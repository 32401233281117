<div class="row">
  <ng-container *ngIf="videoData.length">
    <div class="col-xl-3 col-sm-6 mb-3" *ngFor="let video of videoData; let i = index">
      <div class="video-container shadow rounded-3" *ngIf="videoData">
        <!-- [ngClass]="postId === video.id ? '' : 'video-card'" -->
        <div class="video-card-image">
          <!-- <a class="play-icon">
            <fa-icon
              [icon]="['fas', 'play-circle']"
              class="cursor"
              (click)="playvideo(video)"
            />
          </a> -->
          <a>
            <img *ngIf="!isPlay || postId !== video.id" class="img-fluid h-100 w-100 rounded-top-3" loading="lazy"
              data-src="{{ video?.thumbfilename }}" onerror="this.onerror=null;this.src='/assets/dummy/11.jpg';" alt=""
              (click)="playvideo(video)" />
          </a>
          <div [id]="'jwVideo-' + video.id" class="w-100 c-height"></div>
          <div class="d-flex align-items-center time text-white">
            <div class="font-20-px mx-2 cursor" *ngIf="video.profileid === profileid" (click)="videoEdit(video)">
            <!-- <div class="font-20-px mx-2 cursor" *ngIf="isIncluded(video.channelId)" (click)="videoEdit(video)"> -->
              <fa-icon [icon]="['fas', 'edit']" [fixedWidth]="true" />
            </div>
            <fa-icon [icon]="['fas', 'clock']" [fixedWidth]="true" />
            {{ +video.videoduration | timeDurationWithSec }}
          </div>
        </div>
        <div class="video-card-body cursor">
          
          <div class="video-title text-dark">
            <div [ngbTooltip]="video.albumname" placement="bottom" class="video-page font-16-px">
              {{ video.albumname }}
            </div>
          </div>
          <div class="c-description-height video-description">
            <!-- [title]="video.postdescription" -->
            <a [title]="stripTags(video.postdescription)">{{ stripTags(video.postdescription) }}
            </a>
          </div>
          <div class="video-page text-dark c-height font-16-px" *ngIf="video?.channelName || video?.firstname">
            {{ video?.channelName || video?.firstname }}
          </div>
          <div class="d-flex video-view pt-2">
            <a [routerLink]="['/video/', video.id]" target="_blank">
              <div class="pe-2">
                <fa-icon [icon]="['fas', 'play']" [fixedWidth]="true" />
                <span>Large Player</span>
              </div>
            </a>

            <!-- <fa-icon [icon]="['fas', 'eye']" [fixedWidth]="true" />
            &nbsp;{{ video.viewcount }} &nbsp; -->
            <fa-icon [icon]="['fas', 'calendar']" [fixedWidth]="true" />
            {{ video.postcreationdate | dateDay }}
          </div>
        </div>
      </div>
      <ng-container *ngIf="(i + 1) % 5 === 0 && advertisementDataList?.length && isInnerWidthSmall">
        <ng-container *ngFor="let ad of advertisementDataList | randomAdvertisementUrl: i">
          <a class="img-preview rounded-3 w-100 mt-3" [attr.href]="ad.link ? ad.link : null" [attr.target]="ad.link ? '_blank' : null">
            <img
              loading="lazy"
              [src]="ad.imageUrl"
              class="w-100 h-100 rounded-3 image-render advertise-image"
              alt="advertisement"
            />
          </a>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!videoData.length">
    <div class="col-12 text-center">
      <h6>No data available</h6>
    </div>
  </ng-container>
</div>