<div
  class="jumbotron dash-container mb-0 mx-sm-4 pt-4 videos-section pb-4 rounded mb-4 shadow"
>
  <div>
    <ng-container *ngIf="advertisementDataList.length; else localAdvertisement">
    <!-- <div class="d-flex justify-content-center gap-2 token-img-modal token-hide-mb">
    <ng-container *ngFor="let item of advertisementDataList | slice:0:4">
        <div>
          <img
            loading="lazy"
            [src]="item.imageUrl"
            class="advertise-image h-100 w-100"
            alt=""
          />
        </div>
      </ng-container>
    </div> -->
    <div class="d-flex flex-wrap justify-content-center gap-2 token-img-modal token-hide-mb">
      <ng-container *ngFor="let ad of advertisementDataList | slice:0:4">
        <a class="advertise-image-container" [attr.href]="ad.link ? ad.link : null" [attr.target]="ad.link ? '_blank' : null">
          <img
            loading="lazy"
            data-src="{{ad.imageUrl}}"
            class="advertise-image h-100 w-100 rounded-2"
            alt=""
          />
        </a>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #localAdvertisement>
    <div class="col-12 col-md-12 col-lg-8 m-auto token-img-modal token-hide-mb">
      <img
        loading="lazy"
        data-src="{{ '/assets/img/localFoodslogo.png' }}"
        class="w-100"
        alt=""
      />
    </div>
  </ng-template >
    <div
      class="d-xl-flex column-gap-2 column-gap-xxl-4 col-12 col-md-12 col-lg-8 m-auto px-4 mt-4 justify-content-center mb-view-left-txt c-searchbar"
    >
      <div
        class="form-inline osahan-navbar-search"
        *ngIf="shareService.getCredentials()"
      >
        <div class="input-group">
          <input
            class="form-control c-input-height"
            type="text"
            [(ngModel)]="searchText"
            autocomplete="off"
            placeholder="Search for..."
          />
          <div class="input-group-append c-button">
            <button
              class="btn btn-light"
              type="button"
              [disabled]="!searchText"
              (click)="getSearchData(searchText)"
            >
              <fa-icon [icon]="['fas', 'search']" [fixedWidth]="true" />
            </button>
          </div>
        </div>
        <!-- <div class="input-group form-control c-input-border">
          <fa-icon [icon]="['fas', 'magnifying-glass']" class="m-1" />
          <input
            type="text"
            [(ngModel)]="searchText"
            autocomplete="off"
            (keyup)="getSearchData()"
            placeholder="Search for..."
          />
        </div> -->
        <!-- <div class="input-group-append">
          <button class="btn btn-light" type="button" [disabled]="!searchText.length" (click)="getSearchData()">
            <fa-icon [icon]="['fas', 'search']" [fixedWidth]="true" />
          </button>
        </div> -->
      </div>
    </div>
    <ng-container *ngIf="searchText">
      <div class="col-xxl-4 col-xl-6 col-lg-6 col-10 pt-1 m-auto">
        <div #userSearchDropdownRef ngbDropdown class="c-search-dropdown">
          <div ngbDropdownMenu class="search-user-list w-100">
            <ng-container *ngFor="let user of userList">
              <div
                ngbDropdownItem
                class="d-flex gap-3 pe-auto"
                role="button"
                (click)="openProfile(user.Id)"
              >
                <img
                  loading="lazy"
                  class="w-32-px h-32-px rounded"
                  data-src="{{ user.ProfilePicName }}"
                  [alt]="user.Username"
                  onerror="this.onerror=null;this.src='assets/default-profile.jpg';"
                />
                <span>{{ user.Username }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <div
      *ngIf="mediaApproved && channelId"
      class="d-flex c-bg-color py-2 justify-content-evenly justify-content-md-center column-gap-2 column-gap-xxl-4 col-12 col-md-12 col-lg-8 m-auto mt-4 my-xxl-4 justify-content-center mb-view-left-txt"
    >
      <!-- routerLink="/channels" -->
      <!-- <div
        (click)="getmyChannel()"
        class="d-block d-md-flex gap-2 align-items-center c-text flex-wrap justify-content-center"
      >
        <fa-icon [icon]="['fas', 'user']" [fixedWidth]="true" />
        My Channel
      </div> -->

      <div ngbDropdown class="d-flex flex-column flex-sm-row align-items-center gap-2 d-inline-block btn-group float-end right-action">
        <fa-icon [icon]="['fas', 'user']" [fixedWidth]="true" />
        <a
          (click)="!channelList.length ? getChannels() : null"
          class="d-block d-md-flex gap-2 align-items-center c-text flex-wrap justify-content-center"
          id="userDropdown"
          role="button"
          ngbDropdownToggle
        >
          My Channel
        </a>
        <div
          ngbDropdownMenu
          aria-labelledby="userDropdown"
          class="shadow-lg p-1 mb-5 rounded"
        >
          <ng-container *ngFor="let channel of channelList">
            <div
              class="d-flex justify-content-start align-items-center"
              ngbDropdownItem
              (click)="getmyChannel(channel?.unique_link)"
            >
              <img
                [src]="channel.profile_pic_name"
                alt=""
                class="me-3 h-32-px w-32-px rounded-5"
              />
              <span class="c-text">{{ channel.firstname }}</span>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- <div
        *ngIf="isUserMediaApproved()"
        class="d-flex gap-2 align-items-center c-text flex-wrap justify-content-center"
        (click)="createChannel()"
      >
        <fa-icon [icon]="['fas', 'plus']" [fixedWidth]="true" />
        Create Channel
      </div> -->
      <ng-container *ngIf="channelList.length >= 0">
        <div
          class="d-block d-flex flex-column flex-sm-row gap-2 align-items-center c-text flex-wrap justify-content-center"
          (click)="openVideoUploadPopUp()"
        >
          <fa-icon [icon]="['fas', 'upload']" [fixedWidth]="true" />
          Upload
        </div>
        <div
          class="d-flex flex-column flex-sm-row gap-2 align-items-center c-text flex-wrap justify-content-center"
          (click)="openConferencePopUp()"
        >
          <!-- ngbTooltip="Coming Soon!"
          placement="bottom" -->
          <fa-icon [icon]="['fas', 'plus-square']" [fixedWidth]="true" />
          Conference
          <span class="d-none d-xl-block">& Record</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
